import React, { useState } from "react";
import { getBankImage } from "./Icons";

const Banks = ({ banksList, selectBankId, cancel }) => {
  const [selectedBankId, setSelectedBankId] = useState(null);

  const handleSelectBank = async (bankId) => {
    setSelectedBankId(bankId);
    selectBankId(bankId);
  };

  if (!banksList) {
    return null;
  }

  return (
    <>
      <div className="payment-system__back" onClick={cancel}></div>
      <div className="payment-system__step" data-step="2">
        <div className="payment-system__title">Выберите банк</div>
        <div className="payment-system__banks">
          {banksList.map((bank) => {
            const isActive = selectedBankId === bank.id;
            const buttonClass = `btn btn-outline btn-wide ${
              isActive ? "active" : ""
            }`;

            return (
              <button
                key={bank.id}
                className={buttonClass}
                onClick={() => {
                  handleSelectBank(bank.id);
                }}
              >
                <img src={getBankImage(bank.alias)} alt={bank.name} />
                <span>{bank.name}</span>
              </button>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Banks;
