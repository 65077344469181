import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import PaymentForm from "./PaymentForm";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ToastContainer autoClose={1500} />
        <Routes>
          <Route path="/pay/:guid" element={<PaymentForm />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
