import React, { useState, useEffect } from "react";
import Banks from "./Banks";
import { IconsSelector } from "./Icons";
import useDayjsFormat from "./useDayjsFormat";
import { normalPrice } from "../utils/regExp";

const Form = ({ paymentData, payStart }) => {
  const [email, setEmail] = useState("");
  const [selectedMethod, setSelectedMethod] = useState(null);
  const [selectedMethodGroup, setSelectedMethodGroup] = useState(null);
  const [selectedBankId, setSelectedBankId] = useState(null);
  const [startSelectBank, setStartSelectBank] = useState(false);
  const [startSend, setStartSend] = useState(false);

  const { getFullDate, getFullTime } = useDayjsFormat();

  const selectBankId = async (bankId) => {
    if (selectedMethodGroup) {
      setSelectedBankId(bankId);
    }
  };

  const methods = paymentData?.methods;
  const banksList = paymentData?.banksList;

  const [emailError, setEmailError] = useState(false);
  const emailHandler = (e) => {
    setEmailError(false);
    setEmail(e.target.value);

    if (email.length <= 5) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const needBank = () => {
    return (
      (paymentData?.need_to_select_bank || []).includes(selectedMethodGroup) &&
      !selectedBankId
    );
  };

  const handleOnSubmit = async () => {
    if (needBank()) {
      setStartSelectBank(true);
    } else {
      setStartSend(true);
    }
  };

  const isUnselected = (selectedMethodGroup) => {
    return typeof selectedMethodGroup == "string" ? false : true;
  };

  const selectMethod = (index, system_group) => {
    setSelectedMethod(index);
    setSelectedMethodGroup(system_group);
  };

  const getMethodName = (system_group) => {
    return system_group === "sbp" ? "СБП" : "Картой";
  };

  useEffect(() => {
    if (selectedBankId || startSend) {
      payStart(email, selectedMethodGroup, selectedBankId);
    }
    // eslint-disable-next-line
  }, [selectedBankId, startSend]);

  return startSelectBank ? (
    <Banks
      banksList={banksList}
      selectBankId={selectBankId}
      cancel={() => setStartSelectBank(false)}
    />
  ) : (
    <div className="payment-system__step " data-step="3">
      <div className="payment-system__title">Информация о счете</div>
      <div className="payment-pay">
        <div className="flex-row">
          <div className="text text-white">
            {isUnselected(selectedMethodGroup)
              ? "Выберите способ оплаты"
              : "Текущий способ оплаты"}
          </div>
          <div className="payment-methods">
            {methods.map((method, index) => (
              <button
                onClick={() => selectMethod(index, method.system_group)}
                className={
                  index === selectedMethod
                    ? "btn btn-payment active"
                    : "btn btn-payment"
                }
                key={index}
              >
                <IconsSelector
                  icon={method.system_group}
                  active={index === selectedMethod ? true : false}
                />
                <span>
                  {getMethodName(method.system_group)}
                  {
                    /*
                      <span className="btn-payment__desc"> 
                      Комиссия {method.comission}%
                      </span>
                    */
                  }
                </span>
              </button>
            ))}
          </div>
        </div>

        <div className="flex-row">
          <div className="row">
            <div className="card-small">
              <div className="info">
                <div className="text_md op-5">{paymentData?.shop?.name}</div>
                <div className="text_xl">
                  {normalPrice(paymentData?.payment?.amount)} ₽
                </div>
              </div>
            </div>

            <div className="card-small">
              <div className="info">
                <div className="text">Счет выставлен</div>
                <div className="text text-white">
                  {getFullTime(paymentData?.payment?.cretedAt)}
                  <br />
                  {getFullDate(paymentData?.payment?.cretedAt)}
                </div>
              </div>
            </div>
          </div>

          <div className="">
            <div className="text">Личная информация</div>

            <div
              className={
                emailError
                  ? "input input-light input-danger"
                  : "input input-light"
              }
            >
              <label htmlFor="">Введите вашу почту</label>
              <input
                type="text"
                value={email}
                placeholder="email@gmail.com"
                onChange={(e) => emailHandler(e)}
              />
            </div>
          </div>

          <div
            className="text-center"
            style={{
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            <button
              className="btn btn-payment-success"
              disabled={isUnselected(selectedMethodGroup)}
              onClick={handleOnSubmit}
            >
              Оплатить
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Form;
