import { useTimer } from "react-timer-hook";

function Digits({ value }) {
  const leftDigit = value >= 10 ? value.toString()[0] : "0";
  const rightDigit = value >= 10 ? value.toString()[1] : value.toString();
  return (
    <>
      {leftDigit}
      {rightDigit}
    </>
  );
}

export default function TimerBlock({ expiryTime, callback }) {
  const expiryTimestamp = new Date(expiryTime);
  const { seconds, minutes, isRunning } = useTimer({
    expiryTimestamp,
    onExpire: callback,
  });

  return (
    <>
      <Digits value={minutes} />:<Digits value={seconds} />
    </>
  );
}
