import * as React from "react";

import Sber from "../assets/img/sber.svg";
import Tinkoff from "../assets/img/tk.svg";
import Alpha from "../assets/img/alpha.svg";
import VTB from "../assets/img/vtb.svg";
import Rai from "../assets/img/rai.svg";
import Other from "../assets/img/other.svg";

export const BankImages = {
  Sber,
  Tinkoff,
  Alpha,
  VTB,
  Rai,
  Other,
};

export function getBankImage(alias) {
  switch (alias) {
    case "Sber":
      return Sber;

    case "Tinkoff":
      return Tinkoff;

    case "Alpha":
      return Alpha;

    case "VTB":
      return VTB;

    case "Rai":
      return Rai;

    case "Other":
      return Other;

    default:
      return Other;
  }
}

export function IconsSelector({ icon, active, color }) {
  const activeColor = color ? color : "#3F3B7B";
  const defaultColor = "#fff";

  const mainColor = active ? activeColor : defaultColor;

  switch (icon) {
    case "card":
      return <CardIcon color={mainColor} />;

    case "sbp":
      return <SbpIcon color={mainColor} />;

    case "test":
      return <TestIcon color={mainColor} />;

    default:
      return <TestIcon color={mainColor} />;
  }
}

export function SbpIcon({ color }) {
  return (
    <svg width={44} height={44} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M4.613 9.978l5.156 9.217v5.622l-5.15 9.199-.006-24.038zM24.412 15.841l4.832-2.961 9.888-.01-14.72 9.018v-6.047zM24.384 9.924l.028 12.203-5.169-3.176V.71l5.142 9.214z"
        fill={color}
      />
      <path
        d="M39.132 12.87l-9.889.01-4.858-2.956L19.242.71l19.89 12.16zM24.412 34.067v-5.92l-5.169-3.116.003 18.26 5.166-9.224z"
        fill={color}
      />
      <path
        d="M29.232 31.132L9.769 19.195 4.613 9.978 39.11 31.12l-9.88.012zM19.247 43.29l5.165-9.223 4.82-2.934 9.879-.012-19.864 12.17z"
        fill={color}
      />
      <path
        d="M4.619 34.016l14.666-8.984-4.93-3.026-4.586 2.811-5.15 9.199z"
        fill={color}
      />
    </svg>
  );
}

function CardIcon({ color }) {
  return (
    <svg width={44} height={44} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.624 12.698v-2.435A1.36 1.36 0 0033.27 8.91H5.263c-.745 0-1.354.61-1.354 1.354v2.435h30.715zM13.21 31.28a.57.57 0 010-1.138h8.636a.569.569 0 110 1.138h-8.636zm16.537-4.503c.85 0 1.602.41 2.073 1.041a2.583 2.583 0 110 3.084 2.583 2.583 0 11-2.073-4.126zm-15.749-7.36h4.337c.553 0 1.005.452 1.005 1.005v.22h-6.347v-.22c0-.553.452-1.005 1.005-1.005zm5.342 1.57v2.144H17.37V20.99h1.97zm-2.315 2.144h-1.72V20.99h1.72v2.143zm-2.065 0h-1.967V20.99h1.967v2.143zm4.38.346v.223c0 .553-.452 1.006-1.005 1.006h-4.337a1.009 1.009 0 01-1.005-1.006v-.223h6.347zm-6.13 5.102a.569.569 0 010-1.138h11.452a.568.568 0 110 1.138H13.21zm-9.3-10.106v9.27A1.36 1.36 0 005.263 29.1h3.204V18.474H3.909zm31.624-3.797h3.204c.62 0 1.185.255 1.595.664l.003.004c.41.41.665.975.665 1.595v17.482a2.26 2.26 0 01-.665 1.594l-.006.006a2.258 2.258 0 01-1.592.663H10.73c-.62 0-1.185-.255-1.595-.665l-.004-.003a2.252 2.252 0 01-.664-1.595v-4.414H5.263c-.62 0-1.185-.255-1.595-.665l-.004-.003A2.252 2.252 0 013 27.745V10.263a2.257 2.257 0 01.73-1.659A2.255 2.255 0 015.263 8H33.27c.621 0 1.186.255 1.595.664l.002.002.002-.002c.41.41.664.977.664 1.6v4.413zm-24.803.909h28.007a1.36 1.36 0 011.354 1.354v17.482c0 .742-.612 1.354-1.354 1.354H10.73a1.36 1.36 0 01-1.354-1.354V16.94c0-.745.61-1.354 1.354-1.354z"
        fill={color}
        stroke={color}
        strokeMiterlimit={22.926}
      />
    </svg>
  );
}

export function TestIcon({ color }) {
  return (
    <svg width={44} height={44} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M40.422 10.82c-.545-1.38-1.983-2.868-4.173-2.819H7.75c-2.202-.049-3.64 1.48-4.18 2.876-.577 1.386-.565 2.606-.57 2.724v16.798c.005.116-.007 1.338.57 2.725C4.102 34.498 5.503 36 7.647 36l.103-.001h28.499c.099-.009 1.135.01 2.31-.674 1.185-.634 2.482-2.33 2.44-4.926V13.514c-.007-.12.008-1.326-.577-2.693zM5.414 13.175c.06-.447.23-1.102.555-1.548.333-.439.742-.805 1.781-.825h28.499c1.373.05 1.715.613 2.06 1.292.149.346.234.727.276 1.012.04.286.028.408.038.41v.086H5.375s-.002-.133.04-.427zM37.5 32.821a2.81 2.81 0 01-.888.329c-.25.05-.362.039-.362.047H7.75c-1.362-.047-1.705-.621-2.057-1.325a4.29 4.29 0 01-.278-1.05c-.042-.29-.033-.423-.04-.423V19.2h33.249v11.198c-.041 1.604-.527 2.01-1.125 2.422z"
        fill={color}
      />
    </svg>
  );
}

export function TelegramIcon() {
  return (
    <svg
      width="28px"
      height="28px"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14 0C6.272 0 0 6.272 0 14C0 21.728 6.272 28 14 28C21.728 28 28 21.728 28 14C28 6.272 21.728 0 14 0ZM20.496 9.52C20.286 11.732 19.376 17.108 18.914 19.586C18.718 20.636 18.326 20.986 17.962 21.028C17.15 21.098 16.534 20.496 15.75 19.978C14.518 19.166 13.818 18.662 12.628 17.878C11.242 16.968 12.138 16.464 12.936 15.652C13.146 15.442 16.73 12.18 16.8 11.886C16.8097 11.8415 16.8084 11.7952 16.7962 11.7513C16.784 11.7074 16.7613 11.6671 16.73 11.634C16.646 11.564 16.534 11.592 16.436 11.606C16.31 11.634 14.35 12.936 10.528 15.512C9.968 15.89 9.464 16.086 9.016 16.072C8.512 16.058 7.56 15.792 6.846 15.554C5.964 15.274 5.278 15.12 5.334 14.63C5.362 14.378 5.712 14.126 6.37 13.86C10.458 12.082 13.174 10.906 14.532 10.346C18.424 8.722 19.222 8.442 19.754 8.442C19.866 8.442 20.132 8.47 20.3 8.61C20.44 8.722 20.482 8.876 20.496 8.988C20.482 9.072 20.51 9.324 20.496 9.52Z"
        fill="white"
      />
    </svg>
  );
}
