import React from "react";

const styles = {
  container: {
    textAlign: "center",
    marginTop: 140,
  },
  successText: {
    color: "#7aff00bf",
    lineHeight: 1.5,
  },
  errorText: {
    color: "#cf555ce0",
    lineHeight: 1.5,
  },
  infoText: {
    fontSize: 20,
    color: "grey",
  },
  button: {
    margin: "0 auto",
    height: 20,
  },
};

const ResultPage = ({ url, type, text }) => {
  const isSuccessful = type === "success";

  return (
    <div style={styles.container}>
      <h1 style={isSuccessful ? styles.successText : styles.errorText}>
        {text}
      </h1>
      {isSuccessful && url && (
        <>
          <h1 style={styles.infoText}>Перейти на страницу магазина</h1>
          <button
            className="btn btn-outline"
            style={styles.button}
            onClick={() => (window.location.href = url)}
          >
            Перейти
          </button>
        </>
      )}
    </div>
  );
};

export default ResultPage;
