import { useEffect } from "react";
import "dayjs/locale/ru";
import * as dayjs from "dayjs";

const useDayjsFormat = () => {
  useEffect(() => {
    dayjs.locale("ru");
  }, []);

  const getFullDate = (dateValue) => {
    return dayjs(dateValue).format("D MMMM YYYY");
  };

  const getFullTime = (dateValue) => {
    return dayjs(dateValue).format("HH:mm:ss");
  };

  const isExpired = (dateValue) => {
    if (dateValue) {
      return dayjs().isAfter(dayjs(dateValue));
    } else {
      return false;
    }
  };

  return { getFullDate, getFullTime, isExpired };
};

export default useDayjsFormat;
