const normalCard = (card) => {
  return String(card).replace(/(\d)(?=(\d\d\d\d)+([^\d]|$))/g, "$1 ");
};

const normalPhone = (phone) => {
  return (
    "+ " +
    String(phone).replace(/(\d)(\d{3})(\d\d\d)(\d\d)(\d\d)/g, "$1 $2 $3 $4 $5")
  );
};

export { normalCard, normalPhone };

export const formatFinanceString = (num) => {
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1\u00A0");
};

export const normalPrice = (num) => {
  if (num === undefined || num === null) {
    return null;
  } else {
    if (typeof num === "number") {
      if (num === 0.0) {
        return "0";
      }
      let formattedNum = num.toFixed(2);
      if (formattedNum.endsWith(".00")) {
        formattedNum = formattedNum.substring(0, formattedNum.length - 3);
      }
      return formatFinanceString(formattedNum);
    }
    return formatFinanceString(num);
  }
};
